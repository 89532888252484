/* Background stuff from https://codepen.io/hylobates-lar/pen/qBbQeON */

body {
  background: linear-gradient(-45deg, #e8dfdd, #e6d3da, #d5dde9, #ddeae7);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  background-attachment: fixed; 
  margin: 0;
  overflow-x: hidden; 
}

@keyframes gradient {
  0% {
      background-position: 0% 50%;
  }
  50% {
      background-position: 100% 50%;
  }
  100% {
      background-position: 0% 50%;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.user-card {
  padding: 25px;
  background-color: rgb(115, 123, 234);
  border-radius: 20px;
  width: 350px;
  text-align: center;
}

.user-space {
  margin-left: 25px;
}

.center-cards {
  flex-direction: column;
  justify-content: center;
  /* Center horizontally */
  align-items: center;
  /* Center vertically */
  height: 100vh;
  /* Set the container height to the viewport height for vertical alignment */
}

.content {
  padding: 30px;
}

.best-post {
  background-color: darkgoldenrod;
  border-radius: 10px;
  padding: 25px;
}

.logo {
  max-width: 100%;
  max-height: 70px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.mainLogo {
  max-width: 100%;
  max-height: 100px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.invert {
  filter: invert(0%);
  transition: filter 0.3s ease;
}

.invert:hover {
  filter: invert(1000%);
}


.primary-btn {
  background-color: white;
  color: #181818;
  cursor: pointer;
  padding: 10px 20px;
  font-weight: bold;
  border-radius: 10px;
  transition: background-color 0.2s, color 0.4s;
  font-family: Arial, Helvetica, sans-serif;
}

.primary-btn:hover {
  background-color: #151515;
  color: white;
}

h2 {
  margin: 0;
}